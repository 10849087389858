const LANG_EN = 'en';
const LANG_RU = 'ru';
const FALLBACK_LANGUAGE = LANG_RU;

const LOCALES_ENUM = [LANG_EN, LANG_RU];

const NAMESPACES = {
  main: 'main',
  tools: 'tools',
  generator: 'generator',
  common: 'common',
  error: 'error',
  sitemap: 'sitemap',
  generateImage: 'generate-image',

  imageGeneration: 'image-generation',
  creativity: 'creativity'
};

const ENUM_NAMESPACES = Object.values(NAMESPACES);

module.exports = {
  FALLBACK_LANGUAGE,
  LANG_EN,
  LANG_RU,
  LOCALES_ENUM,
  NAMESPACES,
  ENUM_NAMESPACES
};
